<template>
  <div class="daybook">
    <div class="content-body">
      <div class="row mg-0 no-print">
        <div class="col-sm-5">
          <div class="content-header pd-l-0">
            <div>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <a href="#">Account Report</a>
                  </li>
                  <li
                    class="breadcrumb-item text-capitalize active"
                    aria-current="page"
                  >Daybook
                  </li>
                </ol>
              </nav>
              <h4 class="content-title content-title-xs">Daybook</h4>
            </div>
          </div>
          <!-- content-header -->
        </div>
        <!-- col -->
        <div class="col-sm-0 d-flex justify-content-end col-lg-7 p-1">
          <div class="bg-theam-secondary d-flex m-2 p-1">
            <div class="report-tool-icon mr-3 ml-1">
              <i class="fas fa-print" @click="print"></i>
            </div>
            <!-- <div class="report-tool-icon mr-1">
                        <i class="fas fa-download"></i>
                    </div> -->
          </div>
        </div>
        <!-- col -->
      </div>
      <!-- Row -->
      <div class="bg-theam-secondary table-search-head pt-0 no-print">
        <div class="row">
          <div class="filter-input col-md-12 d-block mr-0 pr-0">
            <select
              class="filter-input mg-t-5 mr-2"
              v-model="filterParams.branch"
              @change="getFilteredDataWithoutDates"
            >
              <option value="">All Branch</option>
              <option
                v-for="branch in branches"
                :key="branch.id"
                :value="branch.id"
              >
                {{ branch.name }}
              </option>
            </select>
            <select
              class="mg-t-5 mr-2"
              name="user"
              v-model="filterParams.user"
              @change="getFilteredDataWithoutDates"
            >
              <option value="">All Users</option>
              <option v-for="user in users" :key="user.id" :value="user.id">
                {{ user.name }}
              </option>
            </select>

            <div class="d-inline-block mr-2">
              <label class="filter-lavel mb-0 mr-1 mg-t-5">From</label>
              <input
                type="date"
                v-model="filterParams.from_date"
                @change="getFilteredDataWithoutDates"
                placeholder="From"
                class="filter-input mg-t-5 mr-1"
              />
              <label class="filter-lavel mb-0 mg-t-5 mr-1">To</label>
              <input
                type="date"
                v-model="filterParams.to_date"
                @change="getFilteredDataWithoutDates"
                placeholder="To"
                class="mg-t-5 filter-input"
              />
            </div>
            <SearchDayMonthComponent
              @clicked="monthDayClicked"
              :show-day="true"
            ></SearchDayMonthComponent>
          </div>
        </div>
      </div>

      <span v-if="load_screen == false">
        <div class="row mg-0 px-2">
          <div class="col-md-4 mt-0 mt-sm-2 px-2 mb-3">
            <h4 class="">Sales Report</h4>
            <table class="table">
              <thead>
                <tr>
                  <th class="wd-70p font-weight-bold">Title</th>
                  <th class="wd-15p text-right table-end-item font-weight-bold">
                    Amount
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Total Sales Amount</td>
                  <td class="text-right table-end-item">
                    {{ parseDigitForSlip(daybookData.total_sales_amount) }}
                  </td>
                </tr>
                <tr>
                  <td>Total Bill Amount</td>
                  <td class="text-right table-end-item">
                    {{ parseDigitForSlip(daybookData.total_sales_bill_amount) }}
                  </td>
                </tr>
                <tr>
                  <td>Total Extra Amount</td>
                  <td class="text-right table-end-item">
                    {{
                      parseDigitForSlip(daybookData.total_sales_extra_amount)
                    }}
                  </td>
                </tr>
                <tr>
                  <td>Total Sales VAT</td>
                  <td class="text-right table-end-item">
                    {{ parseDigitForSlip(daybookData.total_sales_vat_amount) }}
                  </td>
                </tr>
                <!-- <tr>
                  <td>Total Cash Sales</td>
                  <td class="text-right table-end-item">
                    {{
                      parseDigitForSlip(daybookData.total_sales_amount) - parseDigitForSlip(daybookData.total_sales_due_amount)
                    }}
                  </td>
                </tr> -->
                <tr>
                  <td>Total Credit Sales</td>
                  <td class="text-right table-end-item">
                    {{ parseDigitForSlip(daybookData.total_sales_due_amount) }}
                  </td>
                </tr>
                <tr>
                  <td>Total Sales Order</td>
                  <td class="text-right table-end-item">
                    {{ parseDigitForSlip(daybookData.sales_order_amount) }}
                  </td>
                </tr>
                <tr>
                  <td>Total Non-Billed Order</td>
                  <td class="text-right table-end-item">
                    {{ parseDigitForSlip(daybookData.non_billed_sales_order) }}
                  </td>
                </tr>
                <tr>
                  <td>Total Sales order advance</td>
                  <td class="text-right table-end-item">
                    {{
                      parseDigitForSlip(daybookData.total_sales_order_advance)
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-md-4 mt-0 mt-sm-2 px-2 mb-3">
            <h4 class="">Purchase Report</h4>
            <table class="table">
              <thead>
                <tr>
                  <th class="wd-70p font-weight-bold">Title</th>
                  <th class="wd-15p text-right table-end-item font-weight-bold">
                    Amount
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Total Purchase Amount</td>
                  <td class="text-right table-end-item">
                    {{ parseDigitForSlip(daybookData.total_purchase_amount) }}
                  </td>
                </tr>
                <tr>
                  <td>Total Bill Amount</td>
                  <td class="text-right table-end-item">
                    {{
                      parseDigitForSlip(daybookData.total_purchase_bill_amount)
                    }}
                  </td>
                </tr>
                <tr>
                  <td>Total Extra Amount</td>
                  <td class="text-right table-end-item">
                    {{
                      parseDigitForSlip(daybookData.total_purchase_extra_amount)
                    }}
                  </td>
                </tr>
                <tr>
                  <td>Total Purchase VAT</td>
                  <td class="text-right table-end-item">
                    {{
                      parseDigitForSlip(daybookData.total_purchase_vat_amount)
                    }}
                  </td>
                </tr>
                <!-- <tr>
                  <td>Total Cash Purchase</td>
                  <td class="text-right table-end-item">
                    {{
                      parseDigitForSlip(daybookData.total_purchase_amount - daybookData.total_purchase_due_amount)
                    }}
                  </td>
                </tr> -->
                <tr>
                  <td>Total Credit Purchase</td>
                  <td class="text-right table-end-item">
                    {{
                      parseDigitForSlip(daybookData.total_purchase_due_amount)
                    }}
                  </td>
                </tr>
                <tr>
                  <td>Total Purchase Return</td>
                  <td class="text-right table-end-item">
                    {{ parseDigitForSlip(daybookData.total_purchase_return) }}
                  </td>
                </tr>
                <tr>
                  <td>Total Purchase Order</td>
                  <td class="text-right table-end-item">
                    {{ parseDigitForSlip(daybookData.purchase_return_receive) }}
                  </td>
                </tr>
                <tr>
                  <td>Total Non-Billed Order</td>
                  <td class="text-right table-end-item">
                    {{
                      parseDigitForSlip(daybookData.non_billed_purchase_order)
                    }}
                  </td>
                </tr>
                <tr>
                  <td>Total Purchase order advance</td>
                  <td class="text-right table-end-item">
                    {{
                      parseDigitForSlip(
                        daybookData.total_purchase_order_advance
                      )
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-md-4 mt-0 mt-sm-2 px-2 mb-3">
            <h4 class="">Other Report</h4>
            <table class="table">
              <thead>
                <tr>
                  <th class="wd-70p font-weight-bold">Title</th>
                  <th class="wd-15p text-right table-end-item font-weight-bold">
                    Amount
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Total Expense Amount</td>
                  <td class="text-right table-end-item">
                    {{ parseDigitForSlip(daybookData.total_expense_amount) }}
                  </td>
                </tr>
                <tr>
                  <td>Total Other Income</td>
                  <td class="text-right table-end-item">
                    {{ parseDigitForSlip(daybookData.total_income_amount) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <!-- Payment Details -->
        <h4 class="mt-1">Payment Report</h4>
        <div class="bd">
          <table class="table">
            <thead>
              <tr>
                <th class="wd-70p table-start-item">Particular</th>
                <th class="wd-15p text-right table-end-item">
                  Total Dr. Amount
                </th>
                <th class="wd-15p text-right table-end-item">
                  Total Cr. Amount
                </th>
                <th class="scope text-center px-2 no-print"></th>
              </tr>
            </thead>
          </table>
          <table
            class="table"
            v-for="(payment, index) in daybookData.payments"
            :key="'P'+index"
          >
            <thead>
              <tr>
                <th
                  class="wd-70p table-start-item font-weight-bold"
                  v-if="payment.title"
                >
                  {{ payment.title }}
                </th>
                <th class="wd-70p table-start-item font-weight-bold" v-else>
                  {{ payment.bank }}
                </th>
                <th class="wd-15p text-right table-end-item font-weight-bold">
                  {{ parseDigitForSlip(payment.total_debit_incoming) }}
                </th>
                <th class="wd-15p text-right table-end-item font-weight-bold">
                  {{ parseDigitForSlip(payment.total_credit_outgoing) }}
                </th>
                <th class="scope text-center px-1 no-print">
                  <a
                    data-toggle="collapse"
                    :href="'#collapseId' + index"
                    role="button"
                    arial-control="collapseExample"
                    ><i class="fas fa-caret-square-down"></i
                  ></a>
                </th>
              </tr>
            </thead>
            <tbody class="collapse" :id="'collapseId' + index">
              <tr>
                <td class="pl-4">Sales</td>
                <td class="text-right table-end-item">
                  {{ parseDigitForSlip(payment.sales_incoming) }}
                </td>
                <td class="text-right table-end-item">-</td>
              </tr>
              <tr>
                <td class="pl-4">Sales Order</td>
                <td class="text-right table-end-item">
                  {{ parseDigitForSlip(payment.sales_order_incoming) }}
                </td>
                <td class="text-right table-end-item">-</td>
              </tr>
              <tr>
                <td class="pl-4">Purchase</td>
                <td class="text-right table-end-item">-</td>
                <td class="text-right table-end-item">
                  {{ parseDigitForSlip(payment.purchase_outgoing) }}
                </td>
              </tr>
              <tr>
                <td class="pl-4">Purchase Order</td>
                <td class="text-right table-end-item">-</td>
                <td class="text-right table-end-item">
                  {{ parseDigitForSlip(payment.purchase_order_outgoing) }}
                </td>
              </tr>
              <tr>
                <td class="pl-4">Purchase Return</td>
                <td class="text-right table-end-item">
                  {{ parseDigitForSlip(payment.purchase_return_incoming) }}
                </td>
                <td class="text-right table-end-item">-</td>
              </tr>
              <tr>
                <td class="pl-4">Fund Tansferred</td>
                <td class="text-right table-end-item">
                  {{ parseDigitForSlip(payment.amount_transfer_incoming) }}
                </td>
                <td class="text-right table-end-item">
                  {{ parseDigitForSlip(payment.amount_transfer_outgoing) }}
                </td>
              </tr>
               <tr>
                <td class="pl-4">Receipt Voucher</td>
                <td class="text-right table-end-item">
                  {{ parseDigitForSlip(payment.receipt_voucher_incoming) }}
                </td>
                <td class="text-right table-end-item">-
                </td>
              </tr>
               <tr>
                <td class="pl-4">Payment Voucher</td>
                <td class="text-right table-end-item">
                  -
                </td>
                <td class="text-right table-end-item">
                  {{ parseDigitForSlip(payment.payment_voucher_outgoing) }}
                </td>
              </tr>
               <tr>
                <td class="pl-4">Tax</td>
                <td class="text-right table-end-item">
                  {{ parseDigitForSlip(payment.tax_incoming) }}
                </td>
                <td class="text-right table-end-item">{{ parseDigitForSlip(payment.tax_outgoing) }}</td>
              </tr>
              <tr>
                <td class="pl-4">Income</td>
                <td class="text-right table-end-item">
                  {{ parseDigitForSlip(payment.income_incoming) }}
                </td>
                <td class="text-right table-end-item">-</td>
              </tr>
              <tr>
                <td class="pl-4">Expense</td>
                <td class="text-right table-end-item">-</td>
                <td class="text-right table-end-item">
                  {{ parseDigitForSlip(payment.expense_outgoing) }}
                </td>
              </tr>
              <tr>
                <td class="pl-4">Capital</td>
                <td class="text-right table-end-item">
                  {{ parseDigitForSlip(payment.capital_incoming) }}
                </td>
                <td class="text-right table-end-item">
                  {{ parseDigitForSlip(payment.capital_outgoing) }}
                </td>
              </tr>
              <tr>
                <td class="pl-4">Loan</td>
                <td class="text-right table-end-item">
                  {{ parseDigitForSlip(payment.loan_incoming) }}
                </td>
                <td class="text-right table-end-item">
                  {{ parseDigitForSlip(payment.loan_outgoing) }}
                </td>
              </tr>
              <tr>
                <td class="pl-4">Asset</td>
                <td class="text-right table-end-item">
                  {{ parseDigitForSlip(payment.asset_incoming) }}
                </td>
                <td class="text-right table-end-item">
                  {{ parseDigitForSlip(payment.asset_outgoing) }}
                </td>
              </tr>
              <tr>
                <td class="pl-4">Investment</td>
                <td class="text-right table-end-item">
                  {{ parseDigitForSlip(payment.investment_outgoing) }}
                </td>
                <td class="text-right table-end-item">
                  {{ parseDigitForSlip(payment.investment_incoming) }}
                </td>
              </tr>
               <tr>
                <td class="pl-4">Salary Payroll</td>
                <td class="text-right table-end-item">
                  -
                </td>
                <td class="text-right table-end-item">
                  {{ parseDigitForSlip(payment.salary_outgoing) }}
                </td>
              </tr>
            </tbody>
          </table>
          <h4>
            Other Cancellation (Cancelled in selected date but added in other
            date)
          </h4>
          <table class="table">
            <thead>
              <tr>
                <th class="wd-70p table-start-item">Particular</th>
                <th class="wd-15p text-right table-end-item">
                  Total Dr. Amount
                </th>
                <th class="wd-15p text-right table-end-item">
                  Total Cr. Amount
                </th>
                <th class="scope text-center px-2 no-print"></th>
              </tr>
            </thead>
          </table>
          <table
            class="table"
            v-for="(payment, index) in daybookData.payments"
            :key="'C'+index"
          >
            <thead>
              <tr>
                <th
                  class="wd-70p table-start-item font-weight-bold"
                  v-if="payment.title"
                >
                  {{ payment.title }}
                </th>
                <th class="wd-70p table-start-item font-weight-bold" v-else>
                  {{ payment.bank }}
                </th>
                <!-- total must be opposite than above payment totals -->
                <th class="wd-15p text-right table-end-item font-weight-bold">
                  {{ parseDigitForSlip(payment.total_credit_canceled_outgoing) }}
                </th>
                <th class="wd-15p text-right table-end-item font-weight-bold">
                  {{ parseDigitForSlip(payment.total_debit_canceled_incoming) }}
                </th>
                <th class="scope text-center px-1 no-print">
                  <a
                    data-toggle="collapse"
                    :href="'#collapseCancelId' + index"
                    role="button"
                    arial-control="collapseExample"
                    ><i class="fas fa-caret-square-down"></i
                  ></a>
                </th>
              </tr>
            </thead>
            <tbody class="collapse" :id="'collapseCancelId' + index">
              <tr>
                <td class="pl-4">Sales</td>
                <td class="text-right table-end-item">-</td>
                <td class="text-right table-end-item">
                  {{ parseDigitForSlip(payment.sales_incoming_canceled) }}
                </td>
              </tr>
              <tr>
                <td class="pl-4">Sales Order</td>
                <td class="text-right table-end-item">-</td>
                <td class="text-right table-end-item">
                  {{ parseDigitForSlip(payment.sales_order_incoming_canceled) }}
                </td>
              </tr>
              <tr>
                <td class="pl-4">Purchase</td>
                <td class="text-right table-end-item">
                  {{ parseDigitForSlip(payment.purchase_outgoing_canceled) }}
                </td>
                <td class="text-right table-end-item">-</td>
              </tr>
              <tr>
                <td class="pl-4">Purchase Order</td>
                <td class="text-right table-end-item">
                  {{ parseDigitForSlip(payment.purchase_order_outgoing_canceled) }}
                </td>
                <td class="text-right table-end-item">-</td>
              </tr>
              <tr>
                <td class="pl-4">Purchase Return</td>
                <td class="text-right table-end-item">-</td>
                <td class="text-right table-end-item">
                  {{ parseDigitForSlip(payment.purchase_return_incoming_canceled) }}
                </td>
              </tr>
              <tr>
                <td class="pl-4">Fund Tansferred</td>
                <td class="text-right table-end-item">
                  {{ parseDigitForSlip(payment.amount_transfer_outgoing_canceled) }}
                </td>
                <td class="text-right table-end-item">
                  {{ parseDigitForSlip(payment.amount_transfer_incoming_canceled) }}
                </td>
              </tr>
              <tr>
                <td class="pl-4">Tax</td>
                <td class="text-right table-end-item">
                  {{ parseDigitForSlip(payment.tax_outgoing_canceled) }}
                </td>
                <td class="text-right table-end-item">{{ parseDigitForSlip(payment.tax_incoming_canceled) }}</td>
              </tr>
              <tr>
                <td class="pl-4">Receipt Voucher</td>
                <td class="text-right table-end-item">-
                </td>
                <td class="text-right table-end-item">
                  {{ parseDigitForSlip(payment.receipt_voucher_incoming_canceled) }}
                </td>
              </tr>
               <tr>
                <td class="pl-4">Payment Voucher</td>
                <td class="text-right table-end-item">
                  {{ parseDigitForSlip(payment.payment_voucher_outgoing_canceled) }}
                </td>
                <td class="text-right table-end-item">
                  -
                </td>
              </tr>
              <tr>
                <td class="pl-4">Income</td>
                <td class="text-right table-end-item">-</td>
                <td class="text-right table-end-item">
                  {{ parseDigitForSlip(payment.income_incoming_canceled) }}
                </td>
              </tr>
              <tr>
                <td class="pl-4">Expense</td>
                <td class="text-right table-end-item">
                  {{ parseDigitForSlip(payment.expense_outgoing_canceled) }}
                </td>
                <td class="text-right table-end-item">-</td>
              </tr>
              <tr>
                <td class="pl-4">Capital</td>
                <td class="text-right table-end-item">
                  {{ parseDigitForSlip(payment.capital_outgoing_canceled) }}
                </td>
                <td class="text-right table-end-item">
                  {{ parseDigitForSlip(payment.capital_incoming_canceled) }}
                </td>
              </tr>
              <tr>
                <td class="pl-4">Loan</td>
                <td class="text-right table-end-item">
                  {{ parseDigitForSlip(payment.loan_outgoing_canceled) }}
                </td>
                <td class="text-right table-end-item">
                  {{ parseDigitForSlip(payment.loan_incoming_canceled) }}
                </td>
              </tr>
              <tr>
                <td class="pl-4">Asset</td>
                <td class="text-right table-end-item">
                  {{ parseDigitForSlip(payment.asset_outgoing_canceled) }}
                </td>
                <td class="text-right table-end-item">
                  {{ parseDigitForSlip(payment.asset_incoming_canceled) }}
                </td>
              </tr>
              <tr>
                <td class="pl-4">Investment</td>
                <td class="text-right table-end-item">
                  {{ parseDigitForSlip(payment.investment_outgoing_canceled) }}
                </td>
                <td class="text-right table-end-item">
                  {{ parseDigitForSlip(payment.investment_incoming_canceled) }}
                </td>
              </tr>
              <tr>
                <td class="pl-4">Salary Payroll</td>
                <td class="text-right table-end-item">
                  {{ parseDigitForSlip(payment.salary_outgoing_canceled) }}
                </td>
                <td class="text-right table-end-item">
                 -
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </span>
      <span v-else>
        <div id="loading" v-if="load_screen == true">
          <img
            class="img"
            src="../../../../../public/assets/img/loading.gif"
            alt="loading..."
            width="200"
          />
        </div>
      </span>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Services from "./Services/Service";
import SearchDayMonthComponent from "../../../../shared/SearchDayMonthComponent";
export default {
  components: {
    SearchDayMonthComponent,
  },
  data() {
    return {
      sessionYears: ["2021-22"],
      branches: [],
      users: [],
      load_screen: false,
      filterParams: {
        pageIndex: "all",
        branch: "",
        user: "",
        day: new Date().getDate(),
        month: new Date().getMonth(),
        year: new Date().getFullYear(),
        from_date: "",
        to_date: "",
        offset: 0,
        fiscal_year: "2021-22",
      },
      errorMessage: "",
      loading: false,
      error: false,
      pageReload: "",
    };
  },
  computed: {
    ...mapGetters("daybook", ["daybookData"]),
  },
  beforeMount() {
    this.getFilters();
    this.getFilteredDataWithoutDates();
    this.pageReload = true;
  },
  methods: {
    monthDayClicked(value) {
      if (value) {
        this.filterParams.from_date = "";
        this.filterParams.to_date = "";
        this.filterParams.day = value.day;
        this.filterParams.month = value.month;
        this.filterParams.year = value.year;
      }
      //make api call
      this.getFilteredData();
    },
    //print page
    print() {
      window.print();
    },
    getFilters() {
      Services.getFilters()
        .then((res) => {
          this.branches = res.data.data.branchs;
          this.users = res.data.data.users;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // today/month section
    getFilteredData() {
      this.loading = true;
      let params = {
        pageIndex: this.filterParams.pageIndex,
        user: this.filterParams.user,
        branch: this.filterParams.branch,
        today: this.filterParams.day,
        month: this.filterParams.month + 1,
        year: this.filterParams.year,
        from_date: this.filterParams.from_date,
        to_date: this.filterParams.to_date,
        page_reload: this.pageReload,
        fiscal_year: this.filterParams.fiscal_year,
      };
      this.ajaxRequest(params);
    },
    // without today/month section
    getFilteredDataWithoutDates() {
      this.loading = true;
      let params = {
        pageIndex: this.filterParams.pageIndex,
        user: this.filterParams.user,
        branch: this.filterParams.branch,
        today: "",
        month: "",
        year: this.filterParams.year,
        from_date: this.filterParams.from_date,
        to_date: this.filterParams.to_date,
        page_reload: this.pageReload,
        fiscal_year: this.filterParams.fiscal_year,
      };
      this.ajaxRequest(params);
    },

    ajaxRequest(params) {
      console.log(params);
      this.error = false;
      this.load_screen = true;
      Services.getFilteredDaybook(params)
        .then((res) => {
          this.$store.commit("daybook/setDaybookData", res.data.data);
          this.loading = false;
        })
        .catch((error) => {
          this.error = true;
          console.log(error);
          if (
            !Object.prototype.hasOwnProperty.call(
              error,
              "response.data.error_message"
            )
          ) {
            if (error.response.data.error_message == "Access Denied.") {
              this.errorMessage = "Access Denied !!!";
            } else {
              this.errorMessage = "Error fetching data from the server.";
            }
          }
        })
        .finally(() => {
          this.loading = false;
          this.load_screen = false;
        });
    },
  },
};

</script>
<style>
#loading {
  position: absolute;
  z-index: 100;
  margin-left:30%;
  height: 20vh;
  width: 30vw;
  text-align: center;
  line-height: 20vh;
}
.img {
  position: relative;
  margin: auto;
  margin-top: 20%;
}
</style>
