import {store} from "../../../../../store/index";
import Api from "../../../../../store/Services/Api";

class DaybookServices{
    getFilteredDaybook(param){
        const url = `api/daybook/report/filter?page-index=${param.pageIndex}`
        const params ={
            'branch':param.branch,
            'today': param.today,
            'month':param.month,
            'year':param.year,
            'from':param.from_date,
            'to':param.to_date,
            'user':param.user,
            'page-reload':param.page_reload,
            'offset':param.offset
        }
        const headers ={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers,params);
    }
    getFilters(){
        const url = `api/daybook/report/get/filters`
        const headers ={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.get(url,headers);
    }
}

export default new DaybookServices();
